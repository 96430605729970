
/*reset input background color in chrome */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: none;
}

.form-select:focus,
.form-control:focus {
  box-shadow: none;
}
