[data-bs-theme=light] {
  .gray-background {
    background-color: #f5f5f5 !important;


    .buttons {
      button {
        &.btn-outline-light {
          --bs-btn-color: var(--bs-dark);
          --bs-btn-border-color: var(--bs-light);
        }
      }
    }
  }
}

[data-bs-theme=dark] {

  //LIGHTER COLORS FOR DARK MODE
  --bs-primary: hsl(198, 100%, 70%);
  --bs-primary-rgb: rgb(102, 209, 255);
  --bs-info: hsl(207, 73%, 70%);
  --bs-secondary: hsl(35, 91%, 70%);
  --bs-danger: hsl(352, 81%, 70%);
  --bs-border-color: hsl(236, 7%, 40%);

  --bs-dark-bg: hsl(225, 20%, 24%);
  --bs-dark-tint: hsl(224, 42%, 14%);

  .badge.bg-primary{
    background-color: var(--bs-primary) !important;
    color: var(--bs-dark-tint) !important;
  }

  a, button{
    &.btn-primary{
      --bs-btn-bg: var(--bs-primary) !important;
      --bs-btn-color: var(--bs-dark-tint) !important;
      --bs-btn-border-color: var(--bs-primary) !important;
    }
    &.btn-secondary{
      --bs-btn-bg: var(--bs-secondary) !important;
      --bs-btn-color: var(--bs-dark-tint) !important;
      --bs-btn-border-color: var(--bs-secondary) !important;
    }
    &.btn-danger{
      --bs-btn-bg: var(--bs-danger) !important;
      --bs-btn-color: var(--bs-dark-tint) !important;
      --bs-btn-border-color: var(--bs-danger) !important;
    }
  }

  .form-check-input{
    &:checked{
      --bs-form-check-bg: var(--bs-info) !important;
      background-color: var(--bs-form-check-bg) !important;
      border-color: var(--bs-form-check-bg) !important;
    }
  }


  .gray-background {
    background-color: var(--bs-dark-bg) !important;

    .buttons {
      button {
        &.btn-outline-light {
          --bs-btn-color: var(--bs-light);
          --bs-btn-border-color: var(--bs-dark-bg);
        }
        &:hover {
          --bs-btn-hover-color: #ffffff;
          --bs-btn-hover-bg: var(--bs-dark-bg);
          --bs-btn-hover-border-color: var(--bs-dark-bg);
        }
      }
    }

    .container {
      .table-prices {
        background-color: var(--bs-dark-tint) !important;
        box-shadow: rgba(152, 152, 152, 0.3) 0 0 12px 3px !important;
      }
    }
  }

  web-change-language {
    select {
      &:not([class=panel]) {
        color: var(--bs-light);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23f8f9fa' stroke='%23f8f9fa' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='m2 5 6 6 6-6'/%3e%3c/svg%3e") !important;
      }
    }
  }

  #useCases{
    .uses-container{
      div{
        &:hover{
          background-color: var(--bs-dark) !important;
        }
      }
    }
  }

  #features{
    .bg-secondary{
      background-color: var(--bs-dark-tint) !important;
      span{
        background-color: var(--bs-secondary) !important;
        i{
          color: var(--bs-dark-tint) !important;
        }
      }
      h3{
        color: var(--bs-secondary) !important;
      }
      p{
        color: var(--bs-light) !important;
      }
      .btn-primary{
        background-color: var(--bs-secondary) !important;
        border-color: var(--bs-secondary) !important;
        color: var(--bs-dark-tint) !important;
      }
    }
  }

  #pricing{
    button{
      &.bronze, &.silver, &.gold{
        color: var(--bs-light) !important;
      }
    }
  }

  #testimonials {
    .testimonial{
      background-color: var(--bs-dark);
      border-color: var(--bs-accent);
      background-image: url(/assets/web/img/quote.png);
      background-blend-mode: soft-light;
    }
  }

  #contact {
    section {
      background: linear-gradient(var(--bs-dark-bg) 75%, #21283c 25%) !important;
      .container{
        background-color: var(--bs-dark-tint);
      }
      .form-container {
        input, textarea {
          background-color: var(--bs-dark-tint);
        }
      }
      label {
        color: #fff !important;
      }
    }
  }

  footer {
    web-footer {
      .bg-primary {
        background-color: var(--bs-dark-tint) !important;
        background-image: linear-gradient(180deg, rgba($white, .05), rgba($white, 0)) !important;
      }
    }
    web-footer-bottom{
      .bg-primary {
        background-color: var(--bs-dark-tint) !important;
      }
    }
  }

  //USER PANEL
  #userPanel{
    &.offcanvas{
      background: linear-gradient(214.04deg, var(--bs-dark-bg) 25.01%, var(--bs-dark-tint) 70.19%) !important;
    }
  }

  //PRICES
  web-prices-page{
    .contact{
      background-color: var(--bs-dark-bg) !important;
      p{
        color: var(--bs-medium) !important;
      }
    }

    .info-box{
      color: var(--bs-dark) !important;
    }

    .conditions{
      small{
        color: var(--bs-medium) !important;
      }
    }
  }

  //HOW IT WORKS
  .tutorial-container{
    background-color: var(--bs-dark-bg) !important;
    h2 {
      color: var(--bs-medium) !important;
    }
    h3{
      color: var(--bs-primary) !important;
    }
  }
  .docs-container{
    .index-bullet{
      &.active{
        background-color: var(--bs-dark-tint) !important;
        span{
          color: var(--bs-light) !important;
        }
      }
    }

    .docs-list{
      background-color: transparent !important;
      .doc-title{
        color: var(--bs-info) !important;
      }
    }
  }

  //DOCUMENT PAGE
  web-document-page{
    .documents-menu{
      background-color: transparent !important;
      h3{
        color: var(--bs-light) !important;
      }
    }

    strong{
      color: var(--bs-medium) !important;
    }

    .btn-outline-primary{
      color: var(--bs-primary) !important;
      border-color: var(--bs-primary) !important;

      &:hover{
        color: var(--bs-light) !important;
        background-color: var(--bs-primary) !important;
      }
    }
  }

  //MANAGER
  .manager{
    background-color: var(--bs-dark-bg) !important;
  }
  .menu, .menu-bottom{
    .menu-manager {
      background-color: var(--bs-dark-tint) !important;
      border-right-color: var(--bs-dark-tint) !important;

      button{
        background-color: var(--bs-primary);
        border-color: var(--bs-primary);
        color: var(--bs-dark-tint) !important;
        &:hover{
          opacity: 0.9;
          color: var(--bs-light) !important;
        }
      }

      web-event-code-prompt{
        button{
          background-color: var(--bs-dark-tint) !important;
          i{
            color: var(--bs-light) !important;
          }
        }
      }

      li{
        &:hover{
          background-color: var(--bs-dark-bg) !important;
        }
      }
      a.nav-link{
        color: var(--bs-medium) !important;
        &.nav-link-active{
          color: var(--bs-primary) !important;
        }
        &.nav-link-footer-active{
          color: var(--bs-secondary) !important;
        }
      }
    }
  }

  .dashboard{
    .dashboard-col{
      background-color: var(--bs-dark-tint) !important;
      h2{
        color: var(--bs-light);
      }
      .btn-link{
        color: var(--bs-medium);
        &:hover{
          color: white !important;
        }
      }
      .btn-custom-lg{
        background-color: transparent !important;
        border-color: var(--bs-medium) !important;
        box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px 0;
        span, i{
          color: var(--bs-medium) !important;
        }
        &:hover{
          background-color: var(--bs-info) !important;
          border-color: var(--bs-light) !important;
          span, i{
            color: var(--bs-light) !important;
          }
        }
      }

      &:is(.col-user, .col-new, .col-code){
        opacity: 0.9 !important;
      }
    }

    .col-code{
      background-color: var(--bs-dark-tint) !important;
    }
  }

  .event-status-container{
    background-color: var(--bs-dark-bg) !important;
    border-color: var(--bs-dark-bg) !important;

    .btn-outline-primary{
      background-color: transparent !important;
    }

    .event-status-tag{
      cursor: auto;
      background-color: var(--bs-medium) !important;
      color: var(--bs-dark-tint) !important;
      &.running{
        background-color: lightgreen !important;
        color: green !important;
      }
      &.archived{
          background-color:  var(--bs-accent) !important;
          color: var(--bs-dark-tint) !important;
      }
  }

    .event-status-title, .event-status-description{
      color: var(--bs-medium) !important;
      i, .duration-pill{
        color: var(--bs-medium) !important;
      }
    }

    .progress{
      background-color: var(--bs-dark-tint) !important;
      .progress-bar{
        background-color: var(--bs-primary) !important;
      }
    }
    .creation-progress{
      color: var(--bs-medium) !important;
    }

    .btn-outline-primary {
      color: var(--bs-light) !important;
      border-color: var(--bs-light) !important;
    }

    .event-status-time{
      color: var(--bs-accent) !important;
    }

    &.event-highlight{
      border-color: var(--bs-primary) !important;
      .event-status-title{
        color: var(--bs-primary) !important;
      }
    }

    .action-container{
      background-color: var(--bs-dark-tint) !important;
      .btn-text{
        color: var(--bs-light) !important;
        &:hover{
          opacity: 0.7;
        }
      }
      .bi-info-circle-fill, .fw-bold{
        color: var(--bs-accent) !important;
      }
      .btn-accent{
        background-color: var(--bs-light) !important;
        color: var(--bs-dark-tint) !important;
        &:hover{
          opacity: 0.5;
        }
      }
    }
  }

  web-events, web-storage-page, web-destinations-page, web-account, web-organization-account{
    background-color: var(--bs-dark-bg) !important;
    h2{
      color: var(--bs-primary) !important;
      i{
        color: var(--bs-primary) !important;
      }
    }
    p.description{
      color: var(--bs-medium) !important;
    }

    .home-event-list, .accordion-item, .accordion-body, .menu-target, .destination-list-container, .settings-container{
      background-color: var(--bs-dark-tint) !important;
    }

    //Storage Page *Resources component below
    .storage{
      .accordion-item{
        border-color: transparent !important;
        &:not(:has(.collapsed)){
          border-color: var(--bs-primary) !important;
        }
      }
    }
    .accordion-button{
      color: var(--bs-accent) !important;
      &::after{
        color: var(--bs-accent) !important;
      }
      &:not(.collapsed){
        color: var(--bs-primary) !important;
        &::after{
          color: var(--bs-primary) !important;
        }
      }
    }
    .accordion-body{
      i, span{
        color: var(--bs-medium) !important;
      }
    }
    .menu-container{
      background-color: var(--bs-dark-bg) !important;
      li{
        color: var(--bs-medium) !important;
        &.active{
          border-bottom-color: var(--bs-light) !important;
          color: var(--bs-light) !important;
        }
      }
    }

    //Destinations{
    .btn-outline-primary{
      color: var(--bs-light) !important;
      border-color: var(--bs-light) !important;
      &:hover{
        background-color: var(--bs-accent);
      }
    }

    li{
      color: var(--bs-light) !important;
    }

    //Account
    .settings-container{
      h3, small{
        color: var(--bs-medium) !important;
      }
      .settings-item{
        background-color: var(--bs-body-bg) !important;
        a{
          color: var(--bs-light) !important;
          &:hover{
            opacity: 0.7;
          }
        }
        &:not(.text-danger){
          color: var(--bs-light) !important;
        }
      }

      .btn-primary{
        background-color: var(--bs-info) !important;
        border-color: var(--bs-info) !important;
      }

      .btn-dark{
        background-color: var(--bs-light) !important;
        color: var(--bs-accent) !important;
        border-color: var(--bs-light) !important;
      }
    }
  }

  //Assets component
  web-storage-overview{
    .progress{
      background-color: var(--bs-dark-bg) !important;
      .progress-bar{
        background-color: var(--bs-primary) !important;
      }
    }
    i, span{
      color: var(--bs-medium) !important;
    }
    small{
      color: var(--bs-medium) !important;
    }

    .asset-type{
      background-color: var(--bs-dark-bg) !important;
      b{
        color: var(--bs-info) !important;
      }
    }
  }
  .asset-section-container{
    .filters{
      .form-check{
        background-color: var(--bs-body-bg) !important;
        &.active{
          border-color: var(--bs-light) !important;
          color: var(--bs-light) !important;
          &.all{
            background-color: var(--bs-info) !important;
            color: var(--bs-dark-tint) !important;
            span{
              font-weight: 500 !important;
            }
          }
        }
        &:hover{
          border-color: var(--bs-medium) !important;
          color: var(--bs-medium) !important;
          opacity: 0.7;
        }
      }
    }
    .asset-list-container{
      .list-item{
        border-color: var(--bs-accent) !important;
      }
      i, span{
        color: var(--bs-medium) !important;
      }
    }
  }

  //Destinations list component{
  .destination-list-container{
    i, span{
      color: var(--bs-light) !important;
    }
    .destination-container{
      border-bottom-color: var(--bs-accent) !important;
    }
  }

  //Site Page
  web-site-page{
    .site-page-container{
      background-color: var(--bs-dark-bg) !important;

      .button-container{
        background-color: var(--bs-dark-tint) !important;
        button{
          color: var(--bs-light) !important;
        }          
      }
      .event-list.live{
        background-color: var(--bs-dark-tint) !important;        
      }
      .event-list.archived{
        background-color: var(--bs-dark-bg) !important;  
        h2{
          color: var(--bs-secondary) !important;
        }      
      }
    }
  }

  web-event-list-item{
    .event-list-item{
      h3{
        color: var(--bs-medium) !important;
      }
      &.active{
        h3{
          color: var(--bs-info) !important;
        }
      }
    }
    &:hover{
      background-color: var(--bs-body-bg) !important;
    }

  }

  web-organization-banner{
    h1{
      background-color: var(--bs-accent) !important;
      color: var(--bs-light) !important;
    }
  }


  //Studio
  .producer{
    background-color: var(--bs-dark-bg) !important;
    .producer-content{
      .toolbox-partial, .toolbox-single{
        background-color: var(--bs-dark-bg) !important;
      }

      div:has(.output){
        background-color: var(--bs-dark-bg) !important;
      }
    }
    .input-list{
      background-color: var(--bs-dark-tint) !important;
    }

    .toolbox-container{
      .menu-container{
        background-color: var(--bs-dark-bg) !important;
        .nav-tabs{
          .nav-link{
            &.active{
              color: var(--bs-light) !important;
              border-bottom-color: var(--bs-light) !important
            }
            &:hover{
              color: var(--bs-medium) !important;
              border-bottom-color: var(--bs-medium) !important
            }
          }
        }
      }
      .menu-target{
        background-color: var(--bs-dark-tint) !important;
        h5{
          color: var(--bs-light) !important;
        }
        p, i{
          color: var(--bs-medium) !important;
          &:hover{
            opacity: 0.8;
          }
        }
        .list-item{
          border-bottom-color: var(--bs-accent) !important;
        }

        //Overlay
        .accordion-item{
          background-color: var(--bs-dark-bg) !important;
        }
        .accordion-header{
          span, p, i{
            color: var(--bs-medium) !important;
          }
          .accordion-button:not(.collapsed){
            span, p, i{
              color: var(--bs-primary) !important;
            }
          }
        }
        .accordion-body{
          background-color: var(--bs-dark-bg) !important;
        }
        .accordion-images{
          .accordion-item{
            border-bottom-color: var(--bs-accent) !important;
          }
          .accordion-header{
            background-color: var(--bs-dark-bg) !important;
          }
        }
        .btn-light{
          background-color: var(--bs-medium) !important;
          i{
            color: var(--bs-dark-tint) !important;
          }
          &:hover{
            border-color: var(--bs-info) !important;
            i{
              color: var(--bs-info) !important;
            }
          }
        }
        .btn-outline-primary {
          color: var(--bs-light) !important;
          border-color: var(--bs-light) !important;
        }

        //Scenes
        .preset-container{
          .preset-item{
            border-bottom-color: var(--bs-accent) !important;
          }
          .preset-title{
            color: var(--bs-secondary) !important;
          }
          .button-add-container{
            &:hover{
              background-color: var(--bs-light);
              i{
                color: var(--bs-accent) !important;
              }
              border-color: var(--bs-accent) !important;
            }
          }
        }

        //Audio
        .audio-container{
          label{
            color: var(--bs-light) !important;
          }
          .master-container{
            background-color: var(--bs-dark-bg) !important;
          }
          .form-default-container{
            border-bottom-color: var(--bs-accent) !important;
            label{
              color: var(--bs-secondary) !important;
            }
            small{
              color: var(--bs-light) !important;
            }
          }
          .form-slider-container{
            border-bottom-color: var(--bs-accent) !important;
            .form-range
            {
              &::-webkit-slider-runnable-track {
                background-color: var(--bs-accent) !important;
                border: solid 1px var(--bs-border-color) !important;
              }
            }
          }
        }

        //Score
        .score-main-name{
          color: var(--bs-light) !important;
        }
        .score-main-number{
          color: var(--bs-secondary) !important;
        }
        .btn-light.fw-bold{
          background-color: var(--bs-medium) !important;
          border-color: var(--bs-medium) !important;
          color: var(--bs-dark-tint) !important;
        }

        //Live Inputs
        .live-input-list{
          background-color: var(--bs-dark-bg) !important;
          span{
            color: var(--bs-medium) !important;
          }
          .live-input-list-item{
            border-bottom-color: var(--bs-accent) !important;
            i{
              &.text-danger{
                color: var(--bs-danger) !important;
              }
              &.text-success{
                color: var(--bs-success) !important;
              }

            }
          }
        }

        //Resources
        web-input-add-resource{
          .menu-container{
            background-color: var(--bs-dark-tint) !important;
            .nav-tabs{
              .nav-link{
                &.active{
                  color: var(--bs-light) !important;
                  border-bottom-color: transparent !important;
                  background-color: var(--bs-dark-bg) !important;
                }
                &:hover{
                  color: var(--bs-medium) !important;
                }
              }
            }
          }
          .menu-target{
            background-color: var(--bs-dark-bg) !important;
          }
          .resource-list{
            .d-flex.pt-2.pb-2{
              border-bottom-color: var(--bs-accent) !important;
            }
            img{
              border-color: var(--bs-accent) !important;
            }
            .title-text{
              color: var(--bs-medium) !important;
            }
          }
        }
      }
    }
  }

  //Input list
  web-input-list{
    .add-input-name, i{
      color: var(--bs-light) !important;
    }
    button{
      &:hover{
        background-color: var(--bs-light);
        i{
          color: var(--bs-accent) !important;
        }
        border-color: var(--bs-accent) !important;
      }
    }
  }
  web-input-item{
    span, i, .add-input-name, li{
      color: var(--bs-light) !important;
    }
    .input-item-content{
      img, web-viewer-web-rtc, web-broadcaster-web-rtc{
        border-color: var(--bs-accent) !important;
      }
    }
    .input-selected{
      .input-item-content{
        img, web-viewer-web-rtc, web-broadcaster-web-rtc{
            border-color: var(--bs-secondary) !important;
            box-shadow: rgba(194, 194, 194, 0.3) 2px 2px 8px 0 !important;
        }
    }
      span{
        color: var(--bs-secondary) !important;
      }
    }
    .btn-light{
      background-color: var(--bs-accent) !important;
      color: var(--bs-dark-tint) !important;
    }
  }

  //Studio & Live Input Topbar
  web-toolbar-header, web-toolbar-header-publish{
    .toolbar-producer, .toolbar-publisher{
      background-color: var(--bs-dark) !important;
      background-image: var(--bs-gradient) !important;
      background-blend-mode: lighten;
    }
    h4{
      color: var(--bs-light) !important;
    }
    .status-container{
      .dropleft{
        span, i{
          color: var(--bs-primary) !important;
        }
        .code-token{
          color: var(--bs-medium) !important;
        }
        .btn-light{
          i{
            color: var(--bs-dark-tint) !important;
          }
        }
      }
    }
    .status-item-container{
      i{
        color: var(--bs-light) !important;
      }
      .rounded-pill{
        &:not(.bg-primary){
          background-color: var(--bs-medium) !important;
          color: var(--bs-dark) !important;
        }
      }
    }

    .time-info{
      color: var(--bs-medium) !important;
      span{
        border-color: var(--bs-medium) !important;
      }
    }

    .btn.dropdown-toggle{
      i, span{
        color: var(--bs-dark) !important;
      }
      &:hover{
        border-color: var(--bs-primary) !important;
        i, span{
          color: var(--bs-primary) !important;
        }
      }
    }

    .toolbar-destinations{
      i, span{
        color: var(--bs-dark) !important;
      }
      &:hover{
        border-color: var(--bs-primary) !important;
        i, span{
          color: var(--bs-primary) !important;
        }
      }
    }

    .user-logged{
      i{
        color: var(--bs-light) !important;
      }
    }
  }

  //Live Inputs
  .publisher{
    background-color: var(--bs-dark-bg) !important;

    .publisher-container{
      background-color: var(--bs-dark-bg) !important;
    }

    .form-container, .input-container{
      background-color: var(--bs-dark-tint) !important;
      h6, span{
        color: var(--bs-medium) !important;
      }
    }
  }

  .form-switch-container{
    background-color: transparent !important;
    label{
      color: var(--bs-medium) !important;
      opacity: 0.7;
    }
  }

  //Player
  .watch-container{
    background-color: var(--bs-dark-bg) !important;

    .watch-container-limited{
      background-color: var(--bs-dark-tint) !important;

      h2{
        color: var(--bs-info) !important;
      }

      .user-data{
        color: var(--bs-secondary) !important;
      }

    }
  }

  //MODALS
  .modal.fade{
    background-color: rgba(0, 0, 0, 0.3) !important;
    .modal-dialog{
      //box-shadow: rgba(226, 226, 226, 0.3) 2px 2px 8px 0px !important;
    }
  }

  .modal-content{
    --bs-modal-bg: var(--bs-dark-tint) !important;
    --bs-body-bg: var(--bs-dark-tint) !important;
  }
  .modal-header{
    h5, .btn-close{
      color: var(--bs-light) !important;
    }

    .button-back{
      border-color: transparent !important;
      background-color: transparent !important;
      color: var(--bs-light) !important;
    }
    border-bottom-color: var(--bs-accent) !important;

  }

  .input-add-container{
    .btn-outline-primary{
      border-color: var(--bs-accent) !important;
      color: var(--bs-medium) !important;
    }
  }

  .file-type-info{
    background-color: var(--bs-dark-bg) !important;
    color: var(--bs-medium) !important;
  }

  .initialize-container{
    background-color: var(--bs-dark-tint) !important;
    .text-block{
      color: var(--bs-medium) !important;
    }
    .form-container{
      background-color: var(--bs-dark-bg) !important;
    }
    .btn-outline-primary{
      color: var(--bs-light) !important;
      border-color: var(--bs-light) !important;
      &:hover{
        background-color: var(--bs-accent);
      }
    }
  }

  .modal-body{

    //Studio Settings
    .nav-link.active{
      background-color: var(--bs-dark-bg) !important;
      color: var(--bs-medium) !important;
      border-bottom-color: var(--bs-dark-bg) !important;
    }
    .menu-container{
      background-color: transparent !important;
    }
    .menu-target{
      background-color: var(--bs-dark-bg) !important;
      .btn-outline-primary{
        color: var(--bs-light) !important;
        border-color: var(--bs-light) !important;
        &:hover{
          background-color: var(--bs-accent);
        }
      }
    }
    web-producer-settings-score{
      .menu-team-container{
        .nav-link.active{
            background-color: var(--bs-dark-tint) !important;
        }
      }
      .menu-target{
        background-color: var(--bs-dark-tint) !important;
      }
    }
    web-producer-settings-output{
      .nav-link.active{
          border-bottom-color: var(--bs-light) !important;
      }
    }

    web-layout-configuration{
      span, .name {
        color: var(--bs-light) !important;
      }
      .item-favorite{
        background-color: var(--bs-dark-tint) !important;
      }
      .list-item:not(.item-favorite){
        border-bottom: solid 1px var(--bs-accent) !important;
      }
    }

    web-output-confirmation {
      .watch-container{
        span, i{
          color: var(--bs-primary) !important;
        }
        .btn-light{
          i {
            color: var(--bs-dark-tint) !important;
          }
        }
        .code-token{
          color: var(--bs-medium) !important;
        }
      }
    }

    .destination-list-container{
      background-color: var(--bs-dark-tint) !important;
    }
    h5{
      color: var(--bs-secondary) !important;
    }
    .form-label{
      color: var(--bs-medium) !important;
    }
    .list-container{
      background-color: transparent !important;
    }

    //Live Input Settings
    web-broadcaster-settings{
      .broadcaster-settings-container{
        background-color: transparent !important;
        .nav-link{
          border-top-color: transparent !important;
          &.active{
            border-left-color: var(--bs-light) !important;
            border-right-color: var(--bs-dark-bg) !important;
          }
        }
        .body-container{
          background-color: var(--bs-dark-bg) !important;
          border-color: transparent !important;

          .form-switch-container{
            background-color: var(--bs-dark-tint) !important;
          }
          .form-radio-container{
            background-color: var(--bs-dark-tint) !important;
          }
        }
      }
    }
  }

  //User Plan
  web-user-plan {
    .not-available {
      color: var(--bs-accent) !important;
    }
  }

  //FORMS
  .form-floating{
    label{
      color: var(--bs-medium) !important;
      opacity: 0.8;
    }
  }

  //ALERTS
  .alert-container{
    background-color: var(--bs-dark-bg) !important;

    .btn-outline-primary {
      color: var(--bs-light) !important;
      border-color: var(--bs-light) !important;
    }
  }

  //DROPDOWN
  .dropleft{
    li{
      &:hover{
        background-color: var(--bs-dark-bg) !important;
        opacity: 0.8 !important;
      }
    }
  }

  //SCROLLBAR
  ::-webkit-scrollbar {
    background: var(--bs-medium) !important;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--bs-accent) !important;
  }
}
