html {
  overflow-x: hidden;

  body {
    max-width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
    scroll-behavior: smooth;
    padding-right: 0 !important;

    .accordion {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 0.5rem;
      width: 100%;

      .accordion-item {
        background-color: var(--bs-light);
        border: solid 1px var(--bs-border-color) !important;
        border-radius: 0.5rem;
        width: 100%;

        &:first-of-type {
          // border-top-left-radius: 0.5rem;
          // border-top-right-radius: 0.5rem;
        }

        &:last-of-type {
          // border-bottom-left-radius: 0.5rem;
          // border-bottom-right-radius: 0.5rem;
        }
      }

      .accordion-header {
        display: flex;
        align-items: center;
        flex-direction: row;
        height: 3.5rem;

        .btn {
          background-color: white;
          border-color: var(--bs-border-color);
          color: var(--bs-accent);
          font-size: 1rem;
          font-weight: 800;
          margin-right: 1.25rem;

          &:hover {
            background-color: transparent;
            border-color: var(--bs-primary);
            color: var(--bs-primary);
          }
        }
      }

      .accordion-button {
        background-color: transparent;
        padding-left: 52px !important;
        color: var(--bs-dark);

        &:not(.collapsed) {
          box-shadow: none;
          color: var(--bs-primary);
        }

        &::after {
          position: absolute;
          left: 0;
          margin: 0 1rem;
        }

        &:focus {
          border: none;
          outline: 0;
          box-shadow: none;
        }

        &:disabled {
          &::after {
            opacity: 0.3;
          }
        }
      }

      .accordion-body {
        background-color: white;
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
      }
    }

    .btn-link {
      text-decoration: none;
    }

    .btn-primary,
    .btn-secondary,
    .btn-success,
    .btn-info,
    .btn-warning,
    .btn-danger {
      --bs-btn-color: var(--bs-light);
      --bs-btn-hover-color: var(--bs-light);
      --bs-btn-active-color: var(--bs-light);
    }

    .btn-outline-danger {
      --bs-btn-hover-color: var(--bs-light);
      --bs-btn-active-color: var(--bs-light);
    }

    .btn {
      //font-size: 14px;
      font-weight: 400;
    }

   /* .label, h1, h2, h3, h4, h5, h6, button, span, div {
      -webkit-user-select: none; !* Safari *!
      -ms-user-select: none; !* IE 10 and IE 11 *!
      user-select: none; !* Standard syntax *!
    }*/

    .hide-show-pass {
      float: right !important;
      font-size: 20px !important;
      left: auto !important;
      background: transparent !important;
      position: absolute;
      cursor: pointer !important;
      top: 30% !important;
      right: 0.5rem;
    }

    .modal {
      .modal-header {
        h5 {
          color: var(--bs-dark);
        }

        border-color: var(--bs-medium);
      }
    }

    .pass-control {
      background-position: right calc(2.375rem + 0.1875rem) center !important;
    }

    .vh-80 {
      height: 80vh !important;
    }

    .viewer-canvas {
      position: relative;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      aspect-ratio: 16/9;
      border-radius: 8px;
      z-index: 0;
      object-fit: contain;
      //border: 1px solid var(--bs-gray-500);
      //box-shadow: 0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15);
    }

    a {
      &.show-terms,
      &.show-privacy {
        cursor: pointer;
        color: var(--bs-primary);
      }
    }

    .cursor-pointer {
      cursor: pointer;
    }
  }
}
