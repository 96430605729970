// Azzulei fonts
@import "fonts";

// Uncomment only for testing css
/*@import "../../../../node_modules/bootstrap/scss/bootstrap";*/

// Bootstrap required
@import "../../../../node_modules/bootstrap/scss/functions";

//Overrides bootstrap variables
@import "variables";

// Bootstrap required
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../../node_modules/bootstrap/scss/variables-dark";

//Overrides bootstrap variables
@import "maps";

@import "../../../../node_modules/bootstrap/scss/maps";
@import "../../../../node_modules/bootstrap/scss/mixins";
@import "../../../../node_modules/bootstrap/scss/root";

//Icons
@import "../../../../node_modules/bootstrap-icons/font/bootstrap-icons";

// Bootstrap optional
@import "../../../../node_modules/bootstrap/scss/utilities";
@import "../../../../node_modules/bootstrap/scss/reboot";
@import "../../../../node_modules/bootstrap/scss/type";
@import "../../../../node_modules/bootstrap/scss/images";
@import "../../../../node_modules/bootstrap/scss/containers";
@import "../../../../node_modules/bootstrap/scss/grid";
@import "../../../../node_modules/bootstrap/scss/nav";
@import "../../../../node_modules/bootstrap/scss/navbar";
@import "../../../../node_modules/bootstrap/scss/dropdown";
@import "../../../../node_modules/bootstrap/scss/forms";
@import "../../../../node_modules/bootstrap/scss/helpers";
@import "../../../../node_modules/bootstrap/scss/forms/input-group";
@import "../../../../node_modules/bootstrap/scss/forms/form-control";
@import "../../../../node_modules/bootstrap/scss/forms/form-check";
@import "../../../../node_modules/bootstrap/scss/forms/floating-labels";
@import "../../../../node_modules/bootstrap/scss/forms/validation";
@import "../../../../node_modules/bootstrap/scss/buttons";
@import "../../../../node_modules/bootstrap/scss/alert";
@import "../../../../node_modules/bootstrap/scss/spinners";
@import "../../../../node_modules/bootstrap/scss/modal";
@import "../../../../node_modules/bootstrap/scss/offcanvas";
@import "../../../../node_modules/bootstrap/scss/close";
@import "../../../../node_modules/bootstrap/scss/transitions";
@import "../../../../node_modules/bootstrap/scss/accordion";
@import "../../../../node_modules/bootstrap/scss/toasts";
@import "../../../../node_modules/bootstrap/scss/badge";
@import "../../../../node_modules/bootstrap/scss/carousel";
@import "../../../../node_modules/bootstrap/scss/progress";
@import "../../../../node_modules/bootstrap/scss/tooltip";
@import "../../../../node_modules/bootstrap/scss/placeholders";

//Utilities
@import "../../../../node_modules/bootstrap/scss/utilities/api";

//Reset forms elements
@import "form-reset";

//Theme colors
@import "theme";

//Root styles for navbar color for themes
@import "theme-navbar";

//Azzulei icons
@import "icons";
