/****Azz icons*****/
[class^="azz-ico-"], [class*="  azz-ico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'azzulei', serif !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.error {
    color: var(--bs-danger);
  }
}

.azz-ico-all-you-need:before {
  content: "\e900";
}

.azz-ico-assets:before {
  content: "\e901";
}

.azz-ico-azz-logo:before {
  content: "\e902";
}

.azz-ico-clipboard-ok:before {
  content: "\e903";
}

.azz-ico-collaborate:before {
  content: "\e904";
}

.azz-ico-crosshair-ok:before {
  content: "\e905";
}

.azz-ico-easy:before {
  content: "\e906";
}

.azz-ico-flag:before {
  content: "\e907";
}

.azz-ico-google-coloured .path1:before {
  content: "\e908";
  color: rgb(66, 133, 244);
}

.azz-ico-google-coloured .path2:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(52, 168, 83);
}

.azz-ico-google-coloured .path3:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(251, 188, 5);
}

.azz-ico-google-coloured .path4:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(235, 67, 53);
}

.azz-ico-hand-right:before {
  content: "\e90c";
}

.azz-ico-hd:before {
  content: "\e90d";
}

.azz-ico-home:before {
  content: "\e90e";
}

.azz-ico-integration:before {
  content: "\e90f";
}

.azz-ico-list:before {
  content: "\e910";
}

.azz-ico-long-live:before {
  content: "\e911";
}

.azz-ico-microsoft-coloured .path1:before {
  content: "\e912";
  color: rgb(243, 243, 243);
}

.azz-ico-microsoft-coloured .path2:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(243, 83, 37);
}

.azz-ico-microsoft-coloured .path3:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(129, 188, 6);
}

.azz-ico-microsoft-coloured .path4:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(5, 166, 240);
}

.azz-ico-microsoft-coloured .path5:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(255, 186, 8);
}

.azz-ico-multi-device:before {
  content: "\e917";
}

.azz-ico-overlays:before {
  content: "\e918";
}

.azz-ico-person:before {
  content: "\e919";
}

.azz-ico-player:before {
  content: "\e91a";
}

.azz-ico-replay:before {
  content: "\e91b";
}

.azz-ico-replay-add:before {
  content: "\e91c";
}

.azz-ico-score:before {
  content: "\e91d";
}

.azz-ico-settings:before {
  content: "\e91e";
}

.azz-ico-thumb-up-chat:before {
  content: "\e91f";
}

