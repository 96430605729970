// Overrides bootstrap variables with Azzulei corporate color
$font-family-base: Inter, Roboto, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-sans-serif: Inter, Roboto, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace: MonospaceTypewriter, Monofonto, monospace !default;

$font-weight-normal: 300;



$blue:      #1d71b8 !default;
$cyan:      #0096D6 !default;
$orange:    #f3920b !default;
$blue-dark: #172039 !default;
$green:     #2dd36f !default;
$yellow:    #ffc409 !default;
$red:       #eb445a !default;
$dark-grey: #808191 !default;
$light-grey:#E4E4E4 !default;

$primary:       $cyan !default;
$secondary:     $orange !default;
$accent:        $dark-grey !default;
$success:       $green !default;
$info:          $blue !default;
$warning:       $yellow !default;
$danger:        $red !default;
$dark:          $blue-dark !default;
$medium:        $light-grey !default;
