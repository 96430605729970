@font-face {
  font-family: "Monofonto";
  src: local("Monofonto"), local("Monofonto"), url("/assets/shared/fonts/Monofonto.ttf") format("truetype");
}

@font-face {
  font-family: "MonospaceTypewriter";
  font-style: normal;
  src: local("MonospaceTypewriter"), local("MonospaceTypewriter"), url("/assets/shared/fonts/MonospaceTypewriter.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  src: local("Inter Light"), local("Inter-Light"), url("/assets/shared/fonts/Inter-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: local("Inter Regular"), local("Inter-Regular"), url("/assets/shared/fonts/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: local("Inter Medium"), local("Inter-Medium"), url("/assets/shared/fonts/Inter-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src: local("Inter Bold"), local("Inter-Bold"), url("/assets/shared/fonts/Inter-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  src: local("Inter ExtraBold"), local("Inter-ExtraBold"), url("/assets/shared/fonts/Inter-ExtraBold.ttf") format("truetype");
}
/*
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  src: local("Poppins Light"), local("Poppins-Light"), url("/assets/shared/fonts/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: local("Poppins Medium"), local("Poppins-Medium"), url("/assets/shared/fonts/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: local("Poppins SemiBold"), local("Poppins-SemiBold"), url("/assets/shared/fonts/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  src: local("Poppins Bold"), local("Poppins-Bold"), url("/assets/shared/fonts/Poppins-Bold.ttf") format("truetype");
}*/

@font-face {
  font-family: 'azzulei';
  src:  url('/assets/shared/fonts/icomoon.eot?kb4my1');
  src:  url('/assets/shared/fonts/icomoon.eot?kb4my1#iefix') format('embedded-opentype'),
  url('/assets/shared/fonts/icomoon.ttf?kb4my1') format('truetype'),
  url('/assets/shared/fonts/icomoon.woff?kb4my1') format('woff'),
  url('/assets/shared/fonts/icomoon.svg?kb4my1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
