[data-bs-theme=light] {

  nav.navbar {
    background-color: #FFFFFF;

    &.border-bottom-shadow {
      box-shadow: rgba(50, 50, 105, 0.15) 0 2px 5px 0;
    }

    .container-fluid,
    .container {

      div.navbar-collapse {
        ul.navbar-nav {
          li.nav-item {
            &:not([class*=language-selector]) {
              a.nav-link {
                color: var(--bs-dark);
                border-bottom: 2px solid transparent;

                &.active,
                &:hover {
                  color: #000000;
                  border-bottom: 2px solid var(--bs-dark);
                }
              }
            }
          }
        }
      }

      button.navbar-toggler {
        --bs-navbar-color: var(--bs-dark);
      }

      a {
        i {
          color: var(--bs-primary);
        }

        &.manager {
          background-color: var(--bs-primary);
          color: var(--bs-light);

          &:hover {
            background-color: var(--bs-info);
            color: var(--bs-light);

            i {
              color: var(--bs-light);
            }
          }

          i {
            color: var(--bs-light);
          }
        }

        &.login {
          color: var(--bs-dark);
          i {
            color: var(--bs-dark);
          }
        }

      }
    }

    web-change-language {
      button {
        color: var(--bs-dark);
      }
    }

    &.primary {
      web-change-language {
        button {
          color: var(--bs-light);
        }
      }
    }
  }
}

[data-bs-theme=dark] {

  nav.navbar {
    background-color: var(--bs-dark);

    &.border-bottom {
      box-shadow: rgb(232, 229, 229) 0 0 4px 0;
    }

    .container-fluid,
    .container {

      div.navbar-collapse {
        ul.navbar-nav {
          li.nav-item {
            &:not([class*=language-selector], [class*=user-logged]) {

              a.nav-link {
                color: var(--bs-light) !important;
                border-bottom: 2px solid transparent;

                &.active,
                &:hover {
                  color: #FFFFFF;
                  border-bottom: 2px solid var(--bs-light);
                }
              }
            }
          }
        }
      }

      button.navbar-toggler {
        --bs-navbar-color: var(--bs-light);
      }

      a {
        i {
          color: var(--bs-light);
        }

        &.manager {
          background-color: var(--bs-primary) !important;
          color: var(--bs-dark-tint) !important;

          &:hover {
            background-color: var(--bs-info) !important;
            color: white !important;

            i {
              color: white;
            }
          }

          i {
            color: var(--bs-dark-tint);
          }
        }

        &.login {
          color: var(--bs-light);
          i {
            color: var(--bs-light);
          }
        }
      }
    }

    web-change-language {
      button {
        color: var(--bs-light);
      }
    }

  }

}
